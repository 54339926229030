import React from 'react';
import { FaBookmark, FaRegBookmark } from 'react-icons/fa';
import { ClipLoader } from 'react-spinners';

export default function AbaRules({
  abaRules, toggleRuleBookmark, favorites, loading,
}) {
  if (loading) {
    return (
      <div className="aba-rules">
        <div className="aba-rule-list">
          <div className="loader">
            <ClipLoader color="rgba(0, 14, 87, 1)" />
            <div className="name">Loading</div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="aba-rules">
      <div className="aba-rule-list">
        { abaRules.map((rule, index) => (
          <div className="results" key={rule.id}>
            { index === 0 && <div className="title" /> }
            <a
              className="result"
              key={rule.rule_id}
              href={`/aba_model_rules/${rule.rule_id}`}
            >
              <div className="result-number">
                { rule.number }
              </div>
              {favorites.rules && favorites.rules.length > 0 && favorites.rules.some(
                (favoriteRule) => favoriteRule?.id === rule.rule_id,
              ) ? (
                <FaBookmark
                  onClick={(e) => toggleRuleBookmark(e, rule)}
                />
                ) : (
                  <FaRegBookmark
                    className="initially-hidden"
                    onClick={(e) => toggleRuleBookmark(e, rule)}
                  />
                )}
            </a>
          </div>
        ))}
      </div>
    </div>
  );
}
