import React, { useMemo, useState } from 'react';
import Select from 'react-select';

const customStyles = {
  option: (styles, state) => ({
    ...styles,
    marginTop: '-1rem',
    color: state.isSelected ? '#FFF' : '#000E57',
    backgroundColor: state.isSelected ? 'rgba(0, 14, 87, 0.8)' : styles.color,
    '&:hover': {
      color: '#FFF',
      backgroundColor: 'rgba(0, 14, 87, 0.6)',
    },
  }),
  control: (styles, state) => ({
    ...styles,
    boxShadow: state.isFocused ? '0 0 0 0.1rem rgba(0, 14, 87, 0.6)' : 0,
    width: '20rem',
    borderColor: state.isFocused ? 'rgba(0, 14, 87, 0.6)' : '#CED4DA',
    '&:hover': {
      borderColor: state.isFocused ? 'rgba(0, 14, 87, 0.6)' : '#CED4DA',
    },
    marginBottom: '1rem',
  }),
  container: (styles) => ({
    ...styles,
    width: '20rem',
  }),
  menu: (styles) => ({
    ...styles,
    marginTop: '0',
  }),
  singleValue: (styles) => ({
    ...styles,
    color: '#000E57',
  }),
};

export default function DistrictRulesNavigator({
  setDistrictStateFilter, selectDistrict, districtStates, districtStateFilter, allStates,
}) {
  const searchParams = new URLSearchParams(window.location.search);
  const stateFilter = searchParams.get('districtState') || '';
  const districtFilter = searchParams.get('district') || '';
  const [stateSearchValue, setStateSearchValue] = useState(stateFilter);
  const [districtSearchValue, setDistrictSearchValue] = useState(districtFilter);

  const buildDistrictOptions = useMemo(() => {
    const districtState = districtStates.find((state) => state.name === districtStateFilter);
    if (districtState === undefined) return '';
    return districtState.district_courts.map((court) => ({ value: court.name, label: court.name }));
  }, [districtStates, districtStateFilter]);

  const validateStateSearchValue = (newValue) => {
    selectDistrict('');
    if (newValue === null) return setDistrictStateFilter('');
    setStateSearchValue(newValue.label);
    setDistrictSearchValue('');
    return setDistrictStateFilter(newValue.label);
  };

  const validateDistrictSearchValue = (newValue) => {
    if (newValue === null) return selectDistrict('');
    setDistrictSearchValue(newValue.label);
    return selectDistrict(newValue.label);
  };

  return (
    <div className="state-rules-navigator">
      <div className="selector district-selector">
        <Select
          placeholder="Filter by State"
          isSearchable={false}
          value={stateSearchValue === '' ? '' : { value: stateSearchValue, label: stateSearchValue }}
          onChange={validateStateSearchValue}
          options={allStates.map((state) => ({ value: state.name, label: state.name }))}
          styles={customStyles}
        />
        <Select
          placeholder="Filter by District"
          isSearchable={false}
          value={districtSearchValue === '' ? '' : { value: districtSearchValue, label: districtSearchValue }}
          onChange={validateDistrictSearchValue}
          options={buildDistrictOptions}
          styles={customStyles}
        />
      </div>
    </div>
  );
}
