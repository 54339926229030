import React, { useMemo } from 'react';

export default function CategorizedRulesNavigator({ ruleCategories, setRuleFilter, ruleFilter }) {
  const categoriesCount = useMemo(() => ruleCategories.reduce((a, b) => a + b.rules.length, 0), [ruleCategories]);
  return (
    <div className="federal-rules-navigator">
      <div className="category-list">
        <div
          className={`category${ruleFilter === 'All' ? ' selected' : ''}`}
          onClick={() => setRuleFilter('All')}
        >
          <div className="name">
            All
          </div>
          <div className="rule-count">
            { categoriesCount }
          </div>
        </div>
        { ruleCategories.map((category) => (
          <div
            className={`category${category.name === ruleFilter ? ' selected' : ''}`}
            key={category.name}
            onClick={() => setRuleFilter(category.name)}
          >
            <div className="name">
              { category.name }
            </div>
            <div className="rule-count">
              { category.rules.length }
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
